import { Controller } from "stimulus"
import { delay, nextFrame, current, memoize } from "../helpers"

export default class extends Controller {
  static targets = ["absentButton", "resendButton", "sentAtTime"]

  static classes = ["resending"]

  static values = { userId: String, teamIds: Array }

  initialize() {
    if (this.hasResendButtonTarget) {
      this.resendButtonTarget.hidden = !this.showResendButton
    }
    if (this.hasAbsentButtonTarget) {
      this.absentButtonTarget.hidden = !this.showAbsentButton
    }
  }

  // Actions

  async resendStarted() {
    await nextFrame()
    this.element.classList.toggle(this.resendingClass)
    await delay(600).then(nextFrame)
    this.resendButtonTarget.hidden = true
    this.sentAtTime = Date.now()
    this.element.classList.toggle(this.resendingClass)
  }

  // Private

  get showResendButton() {
    return this.isAdministrator && daysSince(this.sentAtTime) <= 7
  }

  get showAbsentButton() {
    return this.isCurrentUser || this.isAdministrator
  }

  get sentAtTime() {
    return this.hasSentAtTimeTarget ? new Date(this.sentAtTimeTarget.getAttribute("datetime")) : undefined
  }

  set sentAtTime(value) {
    this.hasSentAtTimeTarget && this.sentAtTimeTarget.setAttribute("datetime", new Date(value).toISOString())
  }

  get isCurrentUser() {
    return current.user.id == this.userIdValue
  }

  get isAdministrator() {
    return memoize(this, "isAdministrator", this.isAccountAdministrator || this.isTeamAdministrator)
  }

  get isAccountAdministrator() {
    return current.user.administrator
  }

  get isTeamAdministrator() {
    return this.teamIdsValue.some((id) => current.user.administratorTeamIds.includes(id))
  }
}

function daysSince(date) {
  return Math.round((Date.now() - date) / (1000 * 60 * 60 * 24))
}
