import { Controller } from "stimulus"
import { csrfToken } from "../helpers"

export default class extends Controller {
  static classes = ["alert"]

  toggle(e) {
    e.preventDefault()
    const el = e.target

    // Ignore past days
    if (el.classList.contains("past")) {
      return
    }

    el.classList.remove("x-pop")

    if (el.classList.contains("selected")) {
      el.classList.remove("selected")
      this.performToggle(el, "remove")
      this.notifyUser(el.dataset.date, false)
    } else {
      el.classList.add("x-pop")
      el.classList.add("selected")
      this.performToggle(el, "add")
      this.notifyUser(el.dataset.date)
    }
  }

  async performToggle(el, type) {
    el.toggleAttribute("data-toggling", true)
    await this[`${type}Absence`](el.dataset.date)
    el.toggleAttribute("data-toggling", false)
  }

  addAbsence(date) {
    const options = {
      method: "post",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        date: date,
      }),
    }
    return fetch(this.data.get("path"), options)
  }

  notifyUser(date, added = true) {
    let messageEl = document.getElementById(this.data.get("responseElId"))
    let contentsEl = document.createElement("div")

    if (added) {
      contentsEl.classList.add("success")
    } else {
      contentsEl.classList.add("info")
    }

    if (this.hasAlertClass) {
      contentsEl.classList.add(...split(this.alertClass))
    }

    contentsEl.innerHTML = `<p class="mb-0">${this.notifyUserMessage(date, added)}</p>`
    messageEl.innerHTML = ""
    messageEl.appendChild(contentsEl)
  }

  notifyUserMessage(date, added = true) {
    const dateString = formatDate(date)
    const action = added ? "added" : "removed"
    return this.data.get("kind") == "team"
      ? `${dateString}, ${action} as a team holiday.${added ? " Check-ins won't be collected." : ""}`
      : `${dateString}, ${action} as a planned absence.`
  }

  removeAbsence(date) {
    const options = {
      method: "delete",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
      body: JSON.stringify({
        date: date,
      }),
    }
    return fetch(`${this.data.get("path")}/${date}`, options)
  }
}

function split(string = "") {
  return string.match(/[^\s]+/g) || []
}

function formatDate(value) {
  const date = parseDate(value)
  const dayName = isToday(date) ? "Today" : date.toLocaleDateString([], { weekday: "long" })
  const dateString = date.toLocaleDateString([], { dateStyle: "long" })
  return `${dayName}, ${dateString}` //~ "Thursday, April 11, 2024"
}

function parseDate(value) {
  if (value instanceof Date) return value
  const [year, month, day] = value.split("-")
  return new Date(year, month - 1, day)
}

function isToday(date) {
  return date.toDateString() == new Date().toDateString()
}
