import { Controller } from "stimulus"
import { createElement, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["entry", "header"]

  connect() {
    this.scheduleUpdate()
    this.recordUpdate()
  }

  disconnect() {
    this.cancelUpdate()
  }

  // Private

  scheduleUpdate() {
    this.updateTimeout = setTimeout(() => this.update(), this.updateInterval)
  }

  cancelUpdate() {
    clearTimeout(this.updateTimeout)
  }

  recordUpdate() {
    this.lastUpdateAt = new Date()
  }

  async update() {
    const fragment = await this.fetchUpdates()
    if (!fragment) return this.cancelUpdate()

    const targets = this.findTargets(fragment)
    if (targets.length) this.recordUpdate()

    if (this.pageIsVisible) await nextFrame()
    this.removeDuplicateTargets(targets)
    this.element.prepend(fragment)

    this.scheduleUpdate()
  }

  async fetchUpdates() {
    const url = new URL(location)
    url.searchParams.set("after", this.targetId(this.entryTarget))
    url.searchParams.set("since", this.lastUpdateAt.toISOString())

    const response = await fetch(url, { headers: { accept: "text/html; fragment" } })
    const template = createElement("template", { innerHTML: await response.text() })

    if (response.ok) return template.content
  }

  removeDuplicateTargets(targets) {
    for (const target of targets) {
      const id = this.targetId(target)
      if (id) this.findTarget(id)?.remove()
    }
  }

  targetAttribute = `data-${this.identifier}-target`
  idAttribute = `data-${this.identifier}-id`

  findTargets = (root = this.element) => [...root.querySelectorAll(`[${this.targetAttribute}]`)]
  findTarget = (id, root = this.element) => root.querySelector(`[${this.targetAttribute}][${this.idAttribute}="${id}"]`)
  targetId = (target) => target.getAttribute(this.idAttribute)

  get updateInterval() {
    return this.pageIsVisible ? 10000 : 30000
  }

  get pageIsVisible() {
    return document.visibilityState == "visible"
  }
}
