import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["loading"]

  toggle(event) {
    event.preventDefault()
    const { popoverTargetElement, dataset } = event.currentTarget
    popoverTargetElement.querySelector("input[name*=target_id]").value = dataset.feedbackTargetId
    popoverTargetElement.togglePopover()
  }

  showLoadingState({ currentTarget }) {
    currentTarget.classList.add(...this.loadingClasses)
  }

  async complete({ target, currentTarget, detail }) {
    target.reset()
    currentTarget.hidePopover()
    currentTarget.classList.remove(...this.loadingClasses)
    this.element.insertAdjacentHTML("beforeend", await detail.response.text())
  }

  get loadingClasses() {
    return this.loadingClass.split(" ")
  }
}
