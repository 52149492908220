import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  show() {
    const element = this.fieldTarget
    element.classList.remove("hidden")
  }

  hide() {
    const element = this.fieldTarget
    element.classList.add("hidden")
  }
}
