import { Controller } from "stimulus"
import { dispatchInputEvent } from "../helpers"

export default class extends Controller {
  static targets = ["field"]

  focus() {
    if (this.element.open) {
      this.fieldTarget.focus()
    }
  }

  closeOnEscapeKey(event) {
    if (event.key == "Escape") {
      this.close()
    }
  }

  close() {
    this.element.open = false
    this.fieldTarget.value = ""
    dispatchInputEvent(this.fieldTarget)
  }
}
