import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkWhenEnabled", "hideWhenDisabled"]

  toggle(event) {
    if (event.target.checked) {
      for (const target of this.checkWhenEnabledTargets) target.checked = true
      for (const target of this.hideWhenDisabledTargets) target.hidden = false
    } else {
      for (const target of this.hideWhenDisabledTargets) target.hidden = true
    }
  }
}
