import { Controller } from "stimulus"
import { replaceElementWithResponse, submitForm } from "../helpers"

export default class extends Controller {
  static classes = ["loading"]

  submitForm(event) {
    submitForm(event.target.form)
  }

  showLoadingState(event) {
    event.currentTarget.classList.add(...this.loadingClass.split(" "))
  }

  replaceWithResponse(event) {
    replaceElementWithResponse(this.element, event.detail.response)
  }
}
