import { Controller } from "stimulus"

export default class extends Controller {
  preview() {
    document.documentElement.setAttribute("data-theme", this.theme)
  }

  // Private

  get theme() {
    return this.element.value || this.systemTheme
  }

  get systemTheme() {
    return matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  }
}
