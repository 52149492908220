import { delay, nextFrame } from "../../helpers"

class ToastNotificationElement extends HTMLElement {
  async connectedCallback() {
    await nextFrame()
    this.setup()

    await delay(100)
    this.show()

    await delay(this.duration)
    this.hide()

    await delay(500)
    this.remove()
  }

  setup() {
    this.setAttribute("role", "alert")
  }

  show() {
    this.toggleAttribute("visible", true)
  }

  hide() {
    this.toggleAttribute("visible", false)
  }

  get duration() {
    return Number(this.getAttribute("duration") || 2000)
  }

  set duration(duration) {
    this.setAttribute("duration", duration)
  }
}

customElements.define("toast-notification", ToastNotificationElement)
