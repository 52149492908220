import { Controller } from "stimulus"
import { dispatchInputEvent } from "../helpers"

export default class extends Controller {
  static targets = ["restoreDefaultsButton"]

  // Lifecycle

  initialize() {
    this.toggleRestoreDefaultsButton()
  }

  // Actions

  toggleRestoreDefaultsButton() {
    this.restoreDefaultsButtonTarget.classList.toggle("hidden", !this.hasNonDefaultElement)
  }

  restoreDefaults() {
    const elements = this.nonDefaultElements
    if (!elements.length) return
    elements.forEach(restoreDefaultValue)
    elements[0].focus()
  }

  // Private

  get hasNonDefaultElement() {
    return this.elements.some(elementHasNonDefaultValue)
  }

  get nonDefaultElements() {
    return this.elements.filter(elementHasNonDefaultValue)
  }

  get elements() {
    return [...this.element.elements]
  }
}

function elementHasNonDefaultValue(element) {
  const defaultValue = element.getAttribute("data-default")
  return defaultValue && defaultValue != element.value
}

function restoreDefaultValue(element) {
  element.value = element.getAttribute("data-default")
  dispatchInputEvent(element)
}
