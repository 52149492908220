import { domReady, createElement } from "../helpers"

const EXISTING_MEMBER_CHECKBOX_SELECTOR = ".onboarding .form-check.add-existing-member input[type=checkbox]"
const ADD_NEW_MEMBER_LINK_SELECTOR = ".onboarding a.member-form-add-row"

domReady(() => {
  document.querySelectorAll(EXISTING_MEMBER_CHECKBOX_SELECTOR).forEach(installExistingMemberCheckbox)
  document.querySelectorAll(ADD_NEW_MEMBER_LINK_SELECTOR).forEach(installAddNewMemberLink)
})

function installExistingMemberCheckbox(element) {
  const { parentElement, dataset } = element
  const { email, firstName, lastName, timeZone } = dataset

  const appendHiddenInput = (key, value) => {
    const name = `existing_members[][${key}]`
    const input = createElement("input", { name, value, type: "hidden" })
    parentElement.append(input)
  }

  const addHiddenInputs = () => {
    appendHiddenInput("email", email)
    if (firstName) appendHiddenInput("first_name", firstName)
    if (lastName) appendHiddenInput("last_name", lastName)
    if (timeZone) appendHiddenInput("time_zone", timeZone)
  }

  const removeHiddenInputs = () => {
    const inputs = parentElement.querySelectorAll("input[type=hidden]")
    for (const input of inputs) input.remove()
  }

  element.addEventListener("input", () => {
    element.checked ? addHiddenInputs() : removeHiddenInputs()
  })
}

function installAddNewMemberLink(element) {
  const existingRow = element.closest("form").querySelector(".onboarding .member-form")

  element.addEventListener("click", () => {
    const row = existingRow.cloneNode(true)
    const inputs = row.querySelectorAll("input")
    for (const input of inputs) input.value = ""
    existingRow.parentElement.append(row)
    inputs[0].focus()
  })
}
