import { Controller } from "stimulus"
import { uniq } from "../helpers"

export default class extends Controller {
  static targets = ["form", "count"]
  static values = {
    count: Number,
    label: String,
    labelPlural: String,
  }

  initialize() {
    this.updateCount()
  }

  updateCount() {
    this.countValue = this.count
    if (this.countValue > 0) {
      this.countTarget.textContent = `${this.countValue} ${
        this.countValue === 1 ? this.labelValue : this.labelPluralValue
      }`
    } else {
      this.countTarget.textContent = this.labelValue
    }
  }

  get count() {
    return this.uniqueValues.length
  }

  get formKeys() {
    const formData = new FormData(this.formTarget)
    return Array.from(formData.entries())
      .filter(([key, value]) => {
        const element = this.formTarget.querySelector(`[name="${key}"]`)
        return element.type !== "hidden" && value.trim() !== ""
      })
      .map(([key]) => key)
  }

  get uniqueValues() {
    return uniq(this.formKeys)
  }
}
