import { Controller } from "stimulus"
import { split } from "../helpers"

export default class extends Controller {
  static targets = ["previousCompleted", "blocked", "mood", "status"]
  static classes = ["previousCompleted", "blocked", "mood"]

  togglePreviousCompletedStatuses() {
    if (this.hasStatusTarget) {
      this.toggleClass(this.statusTargets, "previousCompleted", ...split(this.previousCompletedClass))
      this.removeClass(this.statusTargets, "blocked", this.blockedClass)
      this.removeClass(this.statusTargets, "mood", this.moodClass)
    }
  }

  toggleBlockedStatuses() {
    if (this.hasStatusTarget) {
      this.toggleClass(this.statusTargets, "blocked", ...split(this.blockedClass))
      this.removeClass(this.statusTargets, "previousCompleted", this.previousCompletedClass)
      this.removeClass(this.statusTargets, "mood", this.moodClass)
    }
  }

  toggleMoodStatuses() {
    if (this.hasStatusTarget) {
      this.toggleClass(this.statusTargets, "mood", ...split(this.moodClass))
      this.removeClass(this.statusTargets, "previousCompleted", this.previousCompletedClass)
      this.removeClass(this.statusTargets, "blocked", this.blockedClass)
    }
  }

  // Private

  toggleClass(obj, selector, ...classNames) {
    for (const item of obj) {
      const dataObject = item.dataset[selector]
      if (dataObject) {
        classNames.map((className) => item.classList.toggle(className))
      }
    }
  }

  removeClass(obj, selector, classNames) {
    for (const item of obj) {
      const dataObject = item.dataset[selector]
      if (dataObject) {
        item.classList.remove(...split(classNames))
      }
    }
  }
}
