import { Controller } from "stimulus"
import { replaceElementWithResponse } from "../helpers"

export default class extends Controller {
  showLoadingState(event) {
    event.currentTarget.dataset.loading = true
  }

  replaceWithResponse(event) {
    replaceElementWithResponse(this.element, event.detail.response)
  }
}
