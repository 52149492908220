import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "input", "output", "bar"]

  connect() {
    this.update()
  }

  clamp(value) {
    return Math.min(Math.max(value, 3), 97)
  }

  switch() {
    const selectValue = this.selectTarget.value
    let className
    if (selectValue == 60) {
      className = "sh-goal-status-warning"
    } else if (selectValue == 30) {
      className = "sh-goal-status-danger"
    } else {
      className = "sh-goal-status-ok"
    }

    if (!this.element.classList.contains(className)) {
      this.element.classList.remove("sh-goal-status-ok", "sh-goal-status-warning", "sh-goal-status-danger")
      this.element.classList.add(className)
    }
  }

  update() {
    const inputValue = this.inputTarget.value
    const clampedInputValue = this.clamp(inputValue)
    this.outputTarget.innerHTML = `${inputValue}%`
    this.barTarget.style.width = `${clampedInputValue}%`
    if (inputValue === "100") {
      this.element.classList.add("sh-goal-status-complete")
    } else {
      this.element.classList.remove("sh-goal-status-complete")
      this.switch()
    }
  }
}
