import { Controller } from "stimulus"
import { escapeHTML, toSentence } from "../helpers"

export default class extends Controller {
  static targets = ["multiselect", "summary"]

  initialize() {
    this.update()
  }

  update() {
    this.summaryTarget.innerHTML = toSentence(this.formattedTeamNames)
  }

  get formattedTeamNames() {
    return this.selectedTeamNames.map(formatTeamName)
  }

  get selectedTeamNames() {
    return this.multiselectTarget.selectedOptionLabels
  }
}

function formatTeamName(teamName) {
  return `<strong>${escapeHTML(teamName)}</strong>`
}
