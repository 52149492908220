import { Controller } from "stimulus"
import { nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["planInput", "planDescription", "addonInput", "addonSummary"]

  initialize() {
    this.update()
  }

  async update() {
    const [selectedPlan] = selectedValues(this.planInputTargets)
    const selectedAddons = selectedValues(this.addonInputTargets)

    await nextFrame()

    for (const target of this.planDescriptionTargets) {
      const { plan } = target.dataset
      target.hidden = plan != selectedPlan
    }

    for (const target of this.addonSummaryTargets) {
      const { addon } = target.dataset
      target.hidden = !selectedAddons.includes(addon)
    }

    for (const target of this.addonInputTargets) {
      const { plan, addon } = target.dataset
      target.disabled = plan != selectedPlan
      target.checked = selectedAddons.includes(addon)
    }
  }
}

function selectedValues(inputs) {
  const values = []
  for (const { checked, disabled, value } of inputs) {
    if (checked && !disabled) values.push(value)
  }
  return values
}
