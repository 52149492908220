import { Controller } from "stimulus"
import { delay, dispatchInputEvent } from "../helpers"

export default class extends Controller {
  static targets = [
    "attachment",
    "attachmentList",
    "fillButtonContainer",
    "previousCompletedInput",
    "previousCompletedSection",
    "previousInput",
    "quickFillButton",
  ]

  static values = {
    autosaveUrl: String,
    intentions: String,
  }

  // Lifecycle

  disconnect() {
    this.stop()
  }

  // Actions

  update() {
    this.toggleFillButton()
    this.scheduleAutosave()
  }

  stop() {
    this.cancelAutosave()
  }

  fillPrevious() {
    if (this.hasPreviousInput) return
    if (this.hasPreviousCompletedInputTarget) {
      this.previousCompletedInputTarget.checked = true
    }
    if (this.hasQuickFillButtonTarget) {
      this.quickFillButtonTarget.click()
      this.previousInputTarget.focus()
    } else {
      inputAssign(this.previousInputTarget, { value: this.intentionsValue })
    }
  }

  fillPreviousIfCompleted() {
    if (this.previousCompletedInputTarget.checked) this.fillPrevious()
    this.toggleCompletedSectionDataAttribute()
  }

  toggleCompletedSectionDataAttribute() {
    if (this.hasPreviousCompletedSectionTarget) {
      this.previousCompletedSectionTarget.dataset.completed = this.previousCompletedInputTarget.checked
    }
  }

  async uncheckMood(event) {
    const { control } = event.currentTarget
    if (!control.checked) return
    await delay()
    inputAssign(control, { checked: false })
  }

  removeAttachment(event) {
    event.preventDefault()
    const attachmentElement = this.attachmentTargets.find((target) => target.contains(event.target))
    const { parentElement } = attachmentElement
    attachmentElement.remove()
    dispatchInputEvent(parentElement)
  }

  // Private

  toggleFillButton() {
    if (!this.hasFillButtonContainerTarget) return
    this.fillButtonContainerTarget.hidden = this.hasPreviousInput
  }

  scheduleAutosave() {
    if (this.autosaveDisabled) return
    if (this.autosaveTimeout) return
    this.autosaveTimeout = setTimeout(async () => {
      await this.autosave()
      delete this.autosaveTimeout
    }, 10 * 1000)
  }

  cancelAutosave() {
    clearTimeout(this.autosaveTimeout)
    delete this.autosaveTimeout
  }

  async autosave() {
    if (this.autosaveDisabled) return
    await fetch(this.autosaveUrl, { body: this.autosaveFormData, method: "put" })
  }

  get autosaveDisabled() {
    return !this.autosaveEnabled
  }

  get autosaveEnabled() {
    return this.hasAutosaveUrlValue
  }

  get autosaveUrl() {
    return new URL(this.autosaveUrlValue, location)
  }

  get autosaveFormData() {
    const formData = new FormData(this.element)
    for (const key of formData.keys()) {
      if (key.includes("attachment")) {
        formData.delete(key)
      }
    }
    return formData
  }

  get hasPreviousInput() {
    return this.previousInputTarget.value.trim().length > 0
  }
}

function inputAssign(input, properties = {}) {
  Object.assign(input, properties)
  dispatchInputEvent(input)
}
