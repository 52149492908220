import { Controller } from "stimulus"
import { nextIdle, nextFrame, regexFromString, stripHTML, memoize } from "../helpers"

export default class extends Controller {
  static targets = [
    "answer",
    "attachment",
    "attachments",
    "attachmentList",
    "attachmentTemplate",
    "blockedSymbol",
    "blockers",
    "blockersAnswer",
    "content",
    "moodImage",
    "moodSymbol",
    "previousCompletedSymbol",
  ]

  static classes = ["previousCompleted"]

  static values = {
    attachmentAlert: String,
    negex: String,
  }

  // Lifecycle

  initialize() {
    this.update()
  }

  // Actions

  update() {
    this.formData = new FormData(this.element)
    this.render()
  }

  // Private

  async render() {
    if (this.rendering) return
    this.rendering = true

    await nextIdle()
    const { previousCompleted, blocked, mood } = this
    const moodHTML = mood ? this.moodImage.outerHTML : ""

    await nextFrame()
    this.previousCompletedSymbolTarget.hidden = !previousCompleted
    this.blockedSymbolTarget.hidden = !blocked
    this.moodSymbolTarget.hidden = !mood
    this.moodSymbolTarget.innerHTML = moodHTML
    this.rendering = false
  }

  getAnswer(key) {
    return this.formData.get(`answer_set[${key}]`)?.trim()
  }

  getAnswers(key) {
    return this.formData.getAll(`answer_set[${key}][]`)
  }

  get previousCompleted() {
    return this.getAnswer("previous_completed") == "1"
  }

  get blockers() {
    return this.getAnswer("blockers")
  }

  get blocked() {
    return this.blockers.length && !this.blockerNegationPattern.test(stripHTML(this.blockers))
  }

  get blockerNegationPattern() {
    return memoize(this, "blockerNegationPattern", regexFromString(this.negexValue))
  }

  get mood() {
    return this.getAnswer("mood")
  }

  get moodImage() {
    return this.moodImageTargets.find((image) => image.getAttribute("data-mood") == this.mood)
  }
}
