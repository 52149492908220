import { createElement, debounceAnimationFrame } from "../../helpers"

export class RichTextAttachmentElement extends HTMLElement {
  static tagName = "rich-text-attachment"

  constructor() {
    super()
    this.render = debounceAnimationFrame(this.render.bind(this))
  }

  // Lifecycle

  static observedAttributes = "content-type filename height preview-url sgid type url width".split(" ")

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue != newValue) {
      this.update()
    }
  }

  connectedCallback() {
    this.update()
  }

  // Rendering

  update() {
    if (!this.isEditable) return
    this.type = isImage(this.contentType) ? "image" : "file"
    this.render()
  }

  render() {
    const link = this.querySelector("a") || createElement("a")
    link.href = this.url

    if (this.type == "image") {
      const image = link.querySelector("img") || createElement("img")

      const src = this.previewUrl || this.url
      if (src != image.src) image.src = src

      if (this.width) image.width = this.width
      if (this.height) image.height = this.height

      if (!image.isConnected) {
        link.replaceChildren(image)
      }
    } else {
      link.download = this.filename

      const content = link.querySelector("span") || createElement("span")
      content.textContent = this.filename

      if (!content.isConnected) {
        link.replaceChildren(content)
      }
    }

    if (!link.isConnected) {
      this.append(link)
    }
  }

  setAttribute(name, value) {
    value ? super.setAttribute(name, value) : this.removeAttribute(name)
  }

  // Attachment properties

  get sgid() {
    return this.getAttribute("sgid")
  }

  set sgid(value) {
    this.setAttribute("sgid", value)
  }

  get contentType() {
    return this.getAttribute("content-type")
  }

  set contentType(value) {
    this.setAttribute("content-type", value)
  }

  get url() {
    return this.getAttribute("url")
  }

  set url(value) {
    this.setAttribute("url", value)
  }

  get previewUrl() {
    return this.getAttribute("preview-url")
  }

  set previewUrl(value) {
    this.setAttribute("preview-url", value)
  }

  get filename() {
    return this.getAttribute("filename")
  }

  set filename(value) {
    this.setAttribute("filename", value)
  }

  get width() {
    return this.getAttribute("width")
  }

  set width(value) {
    this.setAttribute("width", value)
  }

  get height() {
    return this.getAttribute("height")
  }

  set height(value) {
    this.setAttribute("height", value)
  }

  get type() {
    return this.getAttribute("type")
  }

  set type(value) {
    this.setAttribute("type", value)
  }

  // Private

  get isEditable() {
    return this.isConnected && this.hasAttribute("contenteditable")
  }
}

function isImage(contentType) {
  return /^image(\/(gif|png|jpe?g)|$)/.test(contentType)
}

customElements.define(RichTextAttachmentElement.tagName, RichTextAttachmentElement)
