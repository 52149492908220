import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "customInputs", "startInput", "endInput"]

  initialize() {
    this.update()
  }

  // Actions

  update(event) {
    if (this.selectedValue == "custom") {
      this.customInputsTarget.hidden = false
      if (event) this.startInputTarget.focus()
    } else {
      const [startValue, endValue] = this.selectedValue.split("..")
      this.startInputTarget.value = startValue
      this.endInputTarget.value = endValue
      this.customInputsTarget.hidden = true
    }
  }

  // Private

  get selectedValue() {
    return this.selectedOption.value
  }

  get selectedOption() {
    return this.selectTarget.options[this.selectTarget.selectedIndex]
  }
}
