import { Controller } from "stimulus"
import { debounce, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["title"]

  initialize() {
    this.update = debounce(this.update.bind(this), 60)
  }

  async update(event) {
    await nextFrame()
    this.title = event.target.value.trim()
  }

  set title(value) {
    this.titleTarget.textContent = value || this.defaultTitle
  }

  get defaultTitle() {
    return this.titleTarget.dataset.default || ""
  }
}
