import { Controller } from "stimulus"
import { chars } from "../helpers"

export default class extends Controller {
  initialize() {
    const { element } = this

    if (!element.autocomplete) {
      element.autocomplete = "off"
    }

    if (element.placeholder) {
      element.placeholder = insertHiddenSpaces(element.placeholder)
    }

    for (const label of element.labels) {
      for (const node of textNodesIn(label)) {
        node.data = insertHiddenSpaces(node.data)
      }
    }
  }
}

function insertHiddenSpaces(string, space = "\u200b") {
  return chars(string.replaceAll(space, "")).join(space)
}

function* textNodesIn(root) {
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT)
  while (walker.nextNode()) yield walker.currentNode
}
