import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  initialize() {
    this.showTab()
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]

      if (index == this.index) {
        tab.setAttribute("aria-selected", true)
      } else {
        tab.setAttribute("aria-selected", false)
      }

      if (index != this.index) {
        panel.hidden = true
      } else {
        panel.hidden = false
      }
    })
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}
