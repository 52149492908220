import { Controller } from "stimulus"
import { nextFrame } from "../helpers"

export default class extends Controller {
  initialize() {
    this.update()
  }

  async update() {
    const { options, selectedIndex } = this
    // Remove unselected options
    for (const [index, option] of options.entries()) {
      if (index != selectedIndex) option.remove()
    }
    await nextFrame()
    // Compute and set new width
    this.element.style.width = "auto"
    const { width } = this.element.getBoundingClientRect()
    this.element.style.width = `${Math.ceil(width)}px`
    // Restore original options
    this.element.replaceChildren(...options)
  }

  get selectedIndex() {
    return Math.max(this.element.selectedIndex, 0)
  }

  get options() {
    return [...this.element.options]
  }
}
