import { Controller } from "stimulus"
import { capitalize, toSentence } from "../helpers"

export default class extends Controller {
  static targets = ["input", "summary"]

  initialize() {
    this.updateSummary()
  }

  // Actions

  ensureSelectedDay(event) {
    if (this.days.length) return
    event.target.checked = true
  }

  updateSummary() {
    this.summaryTarget.textContent = this.summary
  }

  // Private

  get summary() {
    const { days } = this
    if (days.length == 7) {
      return "day"
    }
    if (days.length == 5 && days.every(isWeekday)) {
      return "weekday"
    }
    return toSentence(days.map(capitalize))
  }

  get days() {
    return this.inputTargets.filter((target) => target.checked).map((target) => target.value)
  }
}

function isWeekday(day) {
  return !day.toLowerCase().startsWith("s")
}
