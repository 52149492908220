import { persistResumableFields, restoreResumableFields, setForm } from "@github/session-resume"

addEventListener("submit", setForm, { capture: true })

addEventListener("pagehide", persistFields)

addEventListener("pageshow", restoreFields)

function persistFields() {
  persistResumableFields(location.pathname, { storage: localStorage })
}

function restoreFields() {
  restoreResumableFields(location.pathname, { storage: localStorage })
}
