import { Controller } from "stimulus"
import { nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["list", "overflowList"]

  static values = {
    count: Number,
    overflowCount: Number,
  }

  initialize() {
    this.items.forEach(this.setIndex)
    this.permanentOverflowItems = this.overflowItems
  }

  connect() {
    this.observer = new ResizeObserver(() => this.render())
    this.observer.observe(this.element.offsetParent)
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }

  // Private

  async render() {
    await nextFrame()

    if (!this.listItemsExceedList) {
      this.listTarget.replaceChildren(...this.sortedItems)
      this.overflowListTarget.replaceChildren(...this.permanentOverflowItems)
    }

    while (this.listItemsExceedList) {
      const item = this.listItems.findLast(this.isOverflowCandidate)
      if (!item) break
      this.overflowListTarget.prepend(item)
    }

    this.countValue = this.listItems.length
    this.overflowCountValue = this.overflowItems.length
  }

  getIndex = (item) => Number(item.getAttribute("data-index"))
  setIndex = (item, index) => item.setAttribute("data-index", index)
  compareIndex = (itemA, itemB) => this.getIndex(itemA) - this.getIndex(itemB)

  isSelected = (item) => item.getAttribute("aria-selected") == "true"
  isPriority = (item) => item.getAttribute("data-priority") == "true"
  isOverflowCandidate = (item) => !this.isSelected(item) && !this.isPriority(item)

  get listItemsExceedList() {
    return this.listTarget.scrollHeight > this.listTarget.offsetHeight
  }

  get sortedItems() {
    return this.items.sort(this.compareIndex)
  }

  get items() {
    return [...this.listItems, ...this.overflowItems]
  }

  get listItems() {
    return [...this.listTarget.children]
  }

  get overflowItems() {
    return [...this.overflowListTarget.children]
  }
}
