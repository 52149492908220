import { Controller } from "stimulus"
import { hasMatch } from "fzy.js"
import { debounce, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["filterable"]

  initialize() {
    this.filter = debounce(this.filter.bind(this), 60)
  }

  async filter(event) {
    const query = event.target.value
    const match = (e) => hasMatch(query, e.dataset.search)
    const state = this.filterableTargets.map((e) => [e, match(e)])
    await nextFrame()
    for (const [element, match] of state) element.hidden = !match
  }
}
