import { Controller } from "stimulus"
import { dispatchInputEvent } from "../helpers"

const TYPES = "reminder summary insights".split(" ")

export default class extends Controller {
  static targets = [
    ...TYPES.flatMap((name) => [
      `${name}Time`,
      `${name}TimeInput`,
      `${name}TimeControl`,
      `${name}Day`,
      `${name}DayControl`,
      `${name}NextDay`,
      `${name}NextDayControl`,
    ]),
    "timeZoneControl",
  ]

  static values = {
    timeZone: String,
  }

  initialize() {
    this.updateSummaries()
  }

  // Actions

  update() {
    this.updateInputs()
    this.updateSummaries()
  }

  enforceStep({ target }) {
    const [hh, mm] = target.value.split(":").map(Number)
    const step = Number(target.step) / 60

    const remainder = mm % step
    if (!remainder) return

    target.value = createTimeString(hh, mm - remainder)
    dispatchInputEvent(target)
  }

  // Private

  updateInputs() {
    for (const type of TYPES) {
      const [timeControl] = this[`${type}TimeControlTargets`]
      const [nextDayControl] = this[`${type}NextDayControlTargets`]

      if (timeControl) {
        let number = Number(timeControl.value.replace(":", ""))
        if (nextDayControl?.checked) number += 2400

        this[`${type}TimeInputTarget`].value = number
      }
    }
  }

  updateSummaries() {
    const { timeZone } = this

    for (const type of TYPES) {
      const [timeControl] = this[`${type}TimeControlTargets`]
      const [dayControl] = this[`${type}DayControlTargets`]
      const [nextDayControl] = this[`${type}NextDayControlTargets`]

      if (timeControl) {
        const timeString = formatTimeString(timeControl.value)
        for (const target of this[`${type}TimeTargets`]) {
          target.textContent = `${timeString} (${timeZone})`
        }
      }

      if (dayControl) {
        const [dayOption] = dayControl.selectedOptions
        for (const target of this[`${type}DayTargets`]) {
          target.textContent = dayOption.label
        }
      }

      if (nextDayControl) {
        const nextDay = nextDayControl.checked
        for (const target of this[`${type}NextDayTargets`]) {
          target.hidden = !nextDay
        }
      }
    }
  }

  get timeZone() {
    return formatTimeZone(this.hasTimeZoneControlTarget ? this.timeZoneControlTarget.value : this.timeZoneValue)
  }
}

function createTimeString(hours, minutes) {
  const hh = `${hours}`.padStart(2, "0")
  const mm = `${minutes}`.padStart(2, "0")
  return `${hh}:${mm}`
}

function formatTimeString(string, options = { hour: "numeric", minute: "numeric" }) {
  const date = new Date(`${new Date().toDateString()} ${string}`)
  return date.toLocaleTimeString([], options)
}

function formatTimeZone(string) {
  return string.replace(/\(.+\)$/g, "").trim()
}
