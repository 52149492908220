export async function editorFromClosestRichTextArea(child, tagName = "rich-text-area") {
  await customElements.whenDefined(tagName)
  const element = child.closest(tagName)

  return new Promise((resolve) => {
    const ready = () => resolve(element.editor)
    if (element.editor) return ready()
    element.addEventListener("ready", ready, { once: true })
  })
}
