import { Controller } from "stimulus"
import { csrfToken, delay, normalizeHTML } from "../helpers"

export default class extends Controller {
  static targets = ["feedbackButton"]
  static values = { url: String, prefix: String }

  // Actions

  async start(event) {
    const button = event.currentTarget
    button.disabled = true

    const url = await createSummary(this.urlValue)
    if (url) {
      const summary = await getSummary(url)
      if (summary) {
        this.insertContent(`${this.prefixValue}${normalizeHTML(summary.html)}`)
        this.feedbackButtonTarget.dataset.feedbackTargetId = summary.id
        button.dataset.completed = true
      }
    }

    button.disabled = !url || !button.hasAttribute("data-rewrite-enabled")
  }

  // Private

  insertContent(content) {
    const command = `${this.element.isBlank ? "set" : "insert"}Content`
    this.element.editor.commands[command](content, { emitUpdate: true })
  }
}

async function createSummary(url) {
  const response = await fetchJSON(url, "POST")
  if (response.redirected) return response.url
}

async function getSummary(url, count = 0) {
  if (count > 20) return
  if (count > 0) await delay(count < 10 ? 500 : 2000)
  const summary = await fetchJSON(url).then((r) => r.json())
  return summary.completed ? summary : getSummary(url, count + 1)
}

function fetchJSON(url, method = "GET") {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken(),
  }
  return fetch(url, { method, headers })
}
