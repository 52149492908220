import { Controller } from "stimulus"
import { maybeSetAppBadge, nextFrame } from "../helpers"

export default class extends Controller {
  static values = {
    badges: Array,
  }

  async initialize() {
    await nextFrame()
    this.updateBadges()
    this.element.remove()
  }

  // Private

  updateBadges() {
    let count = 0
    for (const badge of this.badgesValue) {
      updateBadgeElements(badge)
      count += badge.count
    }
    maybeSetAppBadge(count)
  }
}

function updateBadgeElements(badge) {
  for (const element of findBadgeElements(badge.name)) {
    element.setAttribute("data-badge-count", badge.count)
    element.setAttribute("data-badge-unread", badge.unread)
  }
}

function findBadgeElements(name) {
  return document.querySelectorAll(`[data-badge-name="${name}"]`)
}
