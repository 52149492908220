/* ----------------------------------------------------------------------------
Highlights all text within the element that matches the provided text
---------------------------------------------------------------------------- */

import { createElement, nextFrame, uniq } from "../../helpers"

class HighlightTermElement extends HTMLElement {
  static observedAttributes = ["text"]

  attributeChangedCallback() {
    this.render()
  }

  async render() {
    await nextFrame()
    this.clearMarks()
    this.createMarks()
  }

  clearMarks() {
    for (const node of this.markNodes) {
      node.replaceWith(...node.childNodes)
    }
  }

  createMarks() {
    for (const range of this.textMatchRanges) {
      range.surroundContents(createElement("mark"))
    }
  }

  get markNodes() {
    return this.querySelectorAll("mark")
  }

  get textMatchRanges() {
    return createTextMatchRangeIterator(this, this.text)
  }

  get text() {
    return (this.getAttribute("text") || "").trim()
  }
}

function* createTextMatchRangeIterator(root, text) {
  if (!text) return

  const nodes = createTextNodeIterator(root)
  const termEntries = createTermEntries(text)

  for (const node of nodes) {
    for (const [term, pattern] of termEntries) {
      if (!pattern.test(node.data)) continue

      const endOffset = pattern.lastIndex
      const startOffset = endOffset - term.length

      yield createRange(node, startOffset, endOffset)
    }
  }
}

function* createTextNodeIterator(root) {
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT)
  while (walker.nextNode()) yield walker.currentNode
}

function createTermEntries(text) {
  return uniq(text.split(/\s+/)).filter(Boolean).map(createTermEntry)
}

function createTermEntry(term) {
  return [term, new RegExp(`\\b${term}\\b`, "ig")]
}

function createRange(node, startOffset, endOffset) {
  const range = document.createRange()
  range.setStart(node, startOffset)
  range.setEnd(node, endOffset)
  return range
}

customElements.define("highlight-term", HighlightTermElement)
