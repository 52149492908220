import { getMetaContent } from "./dom_helpers"
import { memoize, camelizeKeys } from "./object_helpers"

class Current {
  get env() {
    return memoize(this, "env", getCurrent("env"))
  }

  get user() {
    return memoize(this, "user", parseCurrent("user"))
  }

  get account() {
    return memoize(this, "account", parseCurrent("account"))
  }
}

export const current = new Current()

function parseCurrent(name) {
  const value = getCurrent(name)
  return value ? camelizeKeys(JSON.parse(value)) : {}
}

function getCurrent(name) {
  return getMetaContent(`current-${name}`)
}
