import { domReady } from "../helpers"

domReady(() => {
  document.querySelectorAll("a.toggle-api-key").forEach(installShowApiKeyButton)
})

function installShowApiKeyButton(button) {
  const element = button.parentElement.querySelector(".api-key .code")
  button.addEventListener("click", () => {
    button.style.display = "none"
    element.style.display = "block"
  })
}
