import { Controller } from "stimulus"
import { current, replaceElementWithResponse, toSentence } from "../helpers"
import { Tooltip } from "../lib/tooltip"

export default class extends Controller {
  static targets = ["menu", "tooltip"]
  static classes = ["loading"]

  initialize() {
    this.installTooltips()
  }

  // Actions

  showLoadingState() {
    this.element.classList.add(this.loadingClass)
    this.menuTarget.open = false
  }

  replaceWithResponse(event) {
    replaceElementWithResponse(this.element, event.detail.response)
  }

  // Private

  installTooltips() {
    for (const target of this.tooltipTargets) {
      const users = JSON.parse(target.getAttribute("data-users"))
      const names = users.map((user, index) => (user.id == current.user.id ? (index ? "you" : "You") : user.first_name))
      target.title = toSentence(names)
      Tooltip.install(target)
    }
  }
}
