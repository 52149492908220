import { editorFromClosestRichTextArea } from "./utils"
import { debounce, nextFrame } from "../../helpers"

class RichTextToolbarElement extends HTMLElement {
  constructor() {
    super()
    this.update = debounce(this.update.bind(this), 100)
  }

  async connectedCallback() {
    this.editor = await editorFromClosestRichTextArea(this)
    if (!this.isConnected) return
    this.editor.on("transaction", this.update)
    this.addEventListener("mousedown", this.handleClick)
    this.addEventListener("keydown", this.handleKey)
    visualViewport.addEventListener("resize", this.handleViewportResize)
  }

  disconnectedCallback() {
    this.editor?.off("transaction", this.update)
    this.removeEventListener("mousedown", this.handleClick)
    this.removeEventListener("keydown", this.handleKey)
    visualViewport.removeEventListener("resize", this.handleViewportResize)
  }

  // Event handlers

  handleClick = (event) => {
    const button = event.target.closest("[data-command]:not([data-input])")
    if (!button) return
    event.preventDefault()
    this.perform(button.getAttribute("data-command"))
  }

  handleKey = (event) => {
    const handler = this.keyHandlers[event.key]
    if (handler) handler(event)
  }

  keyHandlers = {
    Escape: () => {
      this.hide()
    },
    Enter: (event) => {
      event.preventDefault()
      const input = event.target.closest("[data-input][data-command]")
      if (input) this.perform(input.getAttribute("data-command"))
    },
  }

  handleViewportResize = () => {
    if (this.visible) this.updatePosition()
  }

  // Rendering

  async update() {
    await nextFrame()
    if (this.isFocused) return
    this.hide()
    if (!this.shouldShow) return
    this.updateButtons()
    this.updatePosition()
    this.show()
  }

  updateButtons() {
    for (const button of this.buttons) {
      const name = button.getAttribute("data-name")
      const command = button.getAttribute("data-command")
      button.setAttribute("aria-pressed", name && this.editor.isActive(name))
      button.disabled = !this.canPerform(command)
    }
  }

  updatePosition() {
    const { selectionRect, offsetRect, style } = this

    const bottom = offsetRect.bottom - selectionRect.top
    style.bottom = `${bottom}px`

    const left = selectionRect.left - offsetRect.left + selectionRect.width / 2
    style.left = `${left}px`

    const minLeft = offsetRect.left - 30 // Prevent positioning > 30px beyond the container's left edge
    const newLeft = this.rect.left - minLeft
    if (newLeft < 0) style.left = `${left - newLeft}px`
  }

  show() {
    this.visible = true
  }

  hide() {
    this.visible = false
    this.closeDialogs()
  }

  // Commands

  canPerform(command) {
    return this.editor.can().chain().focus()[command]().run()
  }

  perform(command) {
    if (typeof this[command] == "function") {
      this[command]()
    } else {
      this.editor.chain().focus()[command]().run()
    }
  }

  toggleLink() {
    this.openDialog("link")
    const input = this.getInput("link")
    input.value = this.editor.getAttributes("link").href || ""
    input.focus()
  }

  setLink() {
    const href = this.getInput("link").value
    if (!href) return this.unsetLink()
    this.editor.chain().focus().extendMarkRange("link").setLink({ href }).run()
    this.closeDialog("link")
  }

  unsetLink() {
    this.editor.chain().focus().extendMarkRange("link").unsetLink().run()
    this.closeDialog("link")
  }

  // Dialogs

  openDialog(name) {
    this.getDialog(name).open = true
  }

  closeDialog(name) {
    this.getDialog(name).open = false
  }

  closeDialogs() {
    for (const dialog of this.dialogs) dialog.open = false
  }

  getDialog(name) {
    return this.querySelector(`[data-dialog="${name}"]`)
  }

  getInput(name) {
    return this.querySelector(`[data-input="${name}"]`)
  }

  // Properties

  get buttons() {
    return [...this.querySelectorAll("[data-command]")]
  }

  get dialogs() {
    return [...this.querySelectorAll("[data-dialog]")]
  }

  get isFocused() {
    return this.contains(document.activeElement)
  }

  get shouldShow() {
    return this.editor.isFocused && !this.selection.empty
  }

  get selection() {
    return this.editor.selection
  }

  get selectionRect() {
    return this.editor.selectionRect
  }

  get offsetRect() {
    return this.offsetParent.getBoundingClientRect()
  }

  get rect() {
    return this.getBoundingClientRect()
  }

  get visible() {
    return this.hasAttribute("visible")
  }

  set visible(value) {
    this.toggleAttribute("visible", value)
  }
}

customElements.define("rich-text-toolbar", RichTextToolbarElement)
