import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    iconContainerId: String,
  }

  // Actions

  updateIcons() {
    this.iconContainerElement.innerHTML = this.iconHtml
  }

  // Private

  get iconContainerElement() {
    return document.getElementById(this.iconContainerIdValue)
  }

  get iconHtml() {
    return this.selectedOption.getAttribute("data-reminder-icon-html")
  }

  get selectedOption() {
    return this.element.options[this.element.selectedIndex]
  }
}
