import { Controller } from "stimulus"
import { hasMatch } from "fzy.js"
import { debounce, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["field", "list", "item", "search", "term"]

  initialize() {
    this.filter = debounce(this.filter.bind(this), 60)
  }

  connect() {
    this.observer = new MutationObserver(this.filter)
    this.observer.observe(this.listTarget, { childList: true })
  }

  disconnect() {
    this.observer.disconnect()
    this.observer = null
  }

  navigate(event) {
    const distance = { ArrowDown: 1, ArrowUp: -1 }[event.key]
    if (!distance) return
    event.preventDefault()

    const children = [...this.listTarget.children].filter((e) => !e.hidden)
    const oldIndex = children.findIndex((e) => e.contains(document.activeElement))
    const newIndex = (oldIndex + distance + children.length) % children.length

    children[newIndex]?.querySelector("a,button")?.focus()
  }

  async filter() {
    await nextFrame()
    const query = this.fieldTarget.value.trim()
    const match = (e) => query && hasMatch(query, e.dataset.search)
    this.itemTargets.forEach((e) => (e.hidden = !match(e)))
    this.searchTarget.hidden = !query
    this.termTarget.textContent = query
  }
}
