import { createElement, dispatchInputEvent, memoize, uniq } from "../../helpers"

class RichTextAreaElement extends HTMLElement {
  constructor() {
    super()

    this.input = createElement("textarea", {
      readonly: true,
      tabIndex: -1,
      ...this.takeAttributes("name", "value", "required"),
    })
    this.append(this.input)

    this.editorConfig = {
      element: this,
      autofocus: this.autofocus,
      onCreate: this.update,
      onUpdate: this.update,
      editorProps: {
        attributes: this.takeAttributes("placeholder"),
        handleKeyDown: this.handleKeyDown,
        handleDrop: this.handleDrop,
      },
    }

    // If a "keydown" event is cancelled (by ProseMirror) then no "input" event will be fired. To maintain
    // compatibility with existing event handlers (in Stimulus controllers, for example), fire one manually.
    this.addEventListener("keydown", (event) => event.defaultPrevented && dispatchInputEvent(event.target))

    // Track active uploads and disable submit buttons while in progress
    this.uploadCount = 0
    this.addEventListener("attachment:upload-start", this.uploadStarted)
    this.addEventListener("attachment:upload-end", this.uploadEnded)
  }

  // Lifecycle

  async connectedCallback() {
    const { Editor } = await import("./editor")
    if (!this.isConnected) return
    this.connectLabels()
    this.editor = new Editor({ content: this.value, ...this.editorConfig })
    this.dispatchEvent(new CustomEvent("ready"))
  }

  disconnectedCallback() {
    this.editor?.destroy()
    this.disconnectLabels()
  }

  // Event handlers

  update = () => {
    this.value = this.content
    this.empty = this.isEmpty
  }

  uploadStarted = () => {
    this.uploadCount++
    this.toggleSubmitters()
  }

  uploadEnded = () => {
    this.uploadCount--
    this.toggleSubmitters()
  }

  handleKeyDown = (view, event) => {
    // Submit form on Command+Enter
    if (event.key == "Enter" && (event.metaKey || event.ctrlKey)) {
      this.submitter?.click()
      return true
    }
  }

  handleDrop = (view, event) => {
    // Handle dropped files
    const { files } = event.dataTransfer
    if (files.length) {
      this.editor.chain().focus().insertFiles(files).run()
      return true
    }
  }

  focus = () => {
    this.editor.commands.focus()
  }

  // <label> support

  connectLabels() {
    for (const label of this.labels) label.addEventListener("click", this.focus)
  }

  disconnectLabels() {
    for (const label of this.labels) label.removeEventListener("click", this.focus)
  }

  // Utilities

  takeAttribute(name) {
    if (!this.hasAttribute(name)) return
    const value = this.getAttribute(name)
    this.removeAttribute(name)
    return value
  }

  takeAttributes(...names) {
    const attributes = {}
    for (const name of names) {
      const value = this.takeAttribute(name)
      if (value) attributes[name] = value
    }
    return attributes
  }

  toggleSubmitters() {
    for (const submitter of this.submitters) {
      submitter.disabled = this.uploadCount > 0
    }
  }

  // Properties

  get labels() {
    const labels = [...this.form.querySelectorAll(`label[for=${this.id}]`), this.closest("label")]
    return memoize(this, "labels", uniq(labels.filter(Boolean)))
  }

  get submitters() {
    return [...this.form.querySelectorAll("[type=submit],button:not([type=button])")]
  }

  get submitter() {
    return this.submitters.find((submitter) => !submitter.disabled)
  }

  get form() {
    return this.input.form
  }

  get name() {
    return this.input.name
  }

  get value() {
    return this.input.value
  }

  set value(value) {
    if (value == this.value) return
    this.input.value = value
    if (!this.editor) return
    if (value == this.content) return
    this.editor.commands.setContent(value, { emitUpdate: true })
  }

  get content() {
    return this.isBlank ? "" : this.editor.getHTML()
  }

  get isBlank() {
    return this.isEmpty || this.editor.getText().trim() == ""
  }

  get isEmpty() {
    return this.editor.isEmpty
  }

  get placeholder() {
    return this.editor.placeholder
  }

  set placeholder(value) {
    this.editor.placeholder = value
  }

  get autofocus() {
    return this.hasAttribute("autofocus")
  }

  set autofocus(value) {
    this.toggleAttribute("autofocus", value)
  }

  get empty() {
    return this.hasAttribute("empty")
  }

  set empty(value) {
    this.toggleAttribute("empty", value)
  }

  get attachmentsDisabled() {
    return this.hasAttribute("attachments-disabled")
  }

  get disabled() {
    return false
  }

  get defaultValue() {
    return ""
  }
}

// Quack like a <textarea> so `document.querySelector("rich-text-area") instanceof HTMLTextAreaElement` is true.
// Specifically added for compatibility with https://github.com/github/session-resume/blob/23438799bf4347956d1738eae00d822779133b81/src/index.ts#L31
Reflect.setPrototypeOf(RichTextAreaElement.prototype, HTMLTextAreaElement.prototype)

customElements.define("rich-text-area", RichTextAreaElement)
