import "@github/clipboard-copy-element"
import { createElement } from "../../helpers"

addEventListener("clipboard-copy", showNotification)

function showNotification(event) {
  const textContent = getNotificationText(event.target)
  const element = createElement("toast-notification", { textContent, duration: 1000 })
  document.body.append(element)
}

function getNotificationText(element) {
  return element.getAttribute("notification") || getDefaultNotificationText(element)
}

function getDefaultNotificationText(element) {
  return element.value.startsWith("http") ? "Link copied" : "Copied"
}
