import { domReady } from "../helpers"

const SAVE_BUTTON_SELECTOR = "button.save-integration"
const TEST_BUTTON_SELECTOR = "button.test-integration"

domReady(() => {
  document.querySelectorAll(SAVE_BUTTON_SELECTOR).forEach(installSaveButton)
  document.querySelectorAll(TEST_BUTTON_SELECTOR).forEach(installTestButton)
})

function installSaveButton(element) {
  const testButton = element.parentElement.querySelector(TEST_BUTTON_SELECTOR)
  if (testButton) element.addEventListener("click", () => testButton.remove())
}

function installTestButton(element) {
  const saveButton = element.parentElement.querySelector(SAVE_BUTTON_SELECTOR)
  if (saveButton) element.addEventListener("click", () => saveButton.remove())
}
