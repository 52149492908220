import { editorFromClosestRichTextArea } from "./utils"
import { createElement, delay } from "../../helpers"

class RichTextActionbarElement extends HTMLElement {
  async connectedCallback() {
    this.editor = await editorFromClosestRichTextArea(this)
    if (!this.isConnected) return
    this.addEventListener("click", this.handleClick)
  }

  disconnectedCallback() {
    this.removeEventListener("click", this.handleClick)
  }

  // Event handlers

  handleClick = (event) => {
    const button = event.target.closest("[data-command]")
    if (button) this.perform(button.getAttribute("data-command"))
  }

  // Commands

  perform(command) {
    if (typeof this[command] == "function") this[command]()
  }

  async attachFiles() {
    const input = createElement("input", { type: "file", multiple: true, hidden: true })
    input.onchange = () => this.insertFiles(input.files)
    this.append(input)
    input.click()
    await delay()
    input.remove()
  }

  // Helpers

  insertFiles(files) {
    this.editor.chain().focus().insertFiles(files).run()
  }
}

customElements.define("rich-text-actionbar", RichTextActionbarElement)
