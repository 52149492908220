export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelize(string) {
  return string.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase())
}

export function middleTruncate(str) {
  const minimumLength = 20
  const edgeLength = 20
  if (str.length < minimumLength || str.length <= edgeLength * 2) {
    return str
  }
  const edge = new Array(edgeLength + 1).join(".")
  const midLength = str.length - edgeLength * 2
  const re = new RegExp(`(${edge}).{${midLength},}(${edge})`, "g")
  return str.replace(re, "$1...$2")
}

export function toSentence(arr) {
  const opts = {
    two_words_connector: " and ",
    words_connector: ", ",
    last_word_connector: ", and ",
  }
  switch (arr.length) {
    case 0:
      return ""
    case 1:
      return arr[0] + ""
    case 2:
      return `${arr[0]}${opts.two_words_connector}${arr[1]}`
    default:
      return `${arr.slice(0, -1).join(opts.words_connector)}${opts.last_word_connector}${arr.slice(-1)}`
  }
}

export function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
}

// Construct JavaScript RegExp from stringified (via Regexp#inspect) Ruby regex
export function regexFromString(string) {
  const [, pattern, flags] = string.match(/^\/(.+)\/(\w*)$/)
  return new RegExp(pattern, flags)
}

export const wordCount = (() => {
  const pattern =
    /[a-z0-9_\u0392-\u03c9\u00c0-\u00ff\u0600-\u06ff\u0430-\u044f]+|[\u4e00-\u9fff\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/gi

  return (string) => {
    var m = string.match(pattern)
    var count = 0
    if (!m) {
      return 0
    }
    for (var i = 0; i < m.length; i++) {
      if (m[i].charCodeAt(0) >= 0x4e00) {
        count += m[i].length
      } else {
        count += 1
      }
    }
    return count
  }
})()

export const chars = ((segmenter) => {
  return segmenter
    ? (string) => Array.from(segmenter.segment(string), (s) => s.segment)
    : (string) => Array.from(string || [])
})(Intl.Segmenter ? new Intl.Segmenter() : null)

export function split(string = "") {
  return string.match(/[^\s]+/g) || []
}
