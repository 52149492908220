/* ----------------------------------------------------------------------------
details element that defaults to closed @small and open @lg+
---------------------------------------------------------------------------- */

class SidePane extends HTMLElement {
  connectedCallback() {
    this.details = this.querySelector(":scope > details")
    this.summary = this.querySelector(":scope > details > summary")
    this.sidePane = this.querySelector(":scope [data-side-pane]")
    this.sidePane.classList.remove("invisible")

    this.mediaQuery = window.matchMedia("(max-width: 1023px)")
    this.mediaQuery.addEventListener("change", this.update)

    this.update()
  }

  disconnectedCallback() {
    this.mediaQuery.removeEventListener("change", this.update)
    this.mediaQuery = null
  }

  update = () => {
    const { matches } = this.mediaQuery
    this.details.open = !matches
    this.summary.tabIndex = matches ? 0 : -1
  }
}

window.customElements.define("side-pane", SidePane)
