class MenuPopoverElement extends HTMLElement {
  connectedCallback() {
    this.detailsElement = this.querySelector("[data-menu-popover]")
    this.detailsElement.addEventListener("toggle", this.update)

    this.update()
  }

  disconnectedCallback() {
    this.detailsElement.removeEventListener("toggle", this.update)

    this.update()
  }

  // Event handlers

  update = () => {
    this.toggleGlobalEventListeners()
  }

  // Global event handlers

  toggleGlobalEventListeners() {
    const action = this.open && this.isConnected ? "add" : "remove"
    window[`${action}EventListener`]("click", this.closeOnClickOutside)
    window[`${action}EventListener`]("focusin", this.closeOnFocusOutside)
    window[`${action}EventListener`]("keydown", this.closeOnEscapeKey)
  }

  detectOpen() {
    const openMenus = this.querySelectorAll("details[open]:not([data-menu-popover])")
    if (openMenus.length > 0) {
      return true
    } else {
      return false
    }
  }

  closeOnClickOutside = (event) => {
    if (!this.contains(event.target) && this.detectOpen() != true) {
      this.open = false
    }
  }

  closeOnFocusOutside = (event) => {
    if (!this.contains(event.target) && this.detectOpen() != true) {
      this.open = false
    }
  }

  closeOnEscapeKey = (event) => {
    if (event.key == "Escape" && this.detectOpen() != true) {
      this.open = false
    }
  }

  get open() {
    return this.detailsElement.open
  }

  set open(value) {
    this.detailsElement.open = value
  }
}

customElements.define("menu-popover", MenuPopoverElement)
