import { hasMatch } from "fzy.js"
import { sole } from "../../helpers"

class MultiSelectElement extends HTMLElement {
  connectedCallback() {
    this.detailsElement = this.querySelector("details")
    this.summaryElement = this.querySelector("summary")
    this.inputElement = this.querySelector("input[type=search]")
    this.containerElement = this.querySelector("[data-container]")

    this.detailsElement.addEventListener("toggle", this.update)
    this.inputElement.addEventListener("keydown", this.commit)
    this.inputElement.addEventListener("paste", this.pasteOptions)
    this.inputElement.addEventListener("input", this.filterOptions)
    this.containerElement.addEventListener("change", this.updateSummary)

    this.update()
  }

  disconnectedCallback() {
    this.detailsElement.removeEventListener("toggle", this.update)
    this.inputElement.removeEventListener("keydown", this.commit)
    this.inputElement.removeEventListener("paste", this.pasteOptions)
    this.inputElement.removeEventListener("input", this.filterOptions)
    this.containerElement.removeEventListener("change", this.updateSummary)

    this.update()
  }

  // Event handlers

  update = () => {
    this.toggleGlobalEventListeners()
    if (!this.isConnected) return

    this.query = ""
    this.filterOptions()
    this.updateSummary()

    if (this.open) {
      this.inputElement.focus()
    } else {
      this.sortOptions()
    }
  }

  commit = (event) => {
    if (event.key && event.key != "Enter") return
    event.preventDefault()
    event.stopPropagation()

    const option = this.activatePendingOption() || sole(this.selectableOptions)
    if (!option) return

    this.selectOption(option)
    this.sortOptions()
    this.update()
  }

  pasteOptions = (event) => {
    if (!this.allowCreate) return

    const text = event.clipboardData?.getData("text/plain")
    if (!text) return

    const values = this.extractOptionValues(text)
    if (!values.length) return

    event.preventDefault()

    for (const value of values) {
      const option = this.findOrCreateOption(value)
      this.selectOption(option)
      this.addOption(option)
    }

    this.sortOptions()
    this.update()
  }

  filterOptions = () => {
    this.updatePendingOption()

    let optionCount = 0
    const { groups, query } = this

    for (const group of groups) {
      const options = this.findOptions(group)
      optionCount += options.length
      for (const option of options) {
        option.hidden = query && !hasMatch(query, this.labelForOption(option))
      }
      group.hidden = query && this.allHidden(options)
    }

    this.setAttribute("option-count", optionCount)
    this.toggleAttribute("no-results", query && this.allHidden(groups))
  }

  sortOptions = () => {
    for (const group of this.groups) {
      const options = this.findOptions(group)
      group.replaceChildren(...options.sort(this.compareOptions))
    }
  }

  updateSummary = () => {
    this.summary = this.open || this.selectedOptionCount ? this.selectedOptionSummary : ""
  }

  // Global event handlers

  toggleGlobalEventListeners() {
    const action = this.open && this.isConnected ? "add" : "remove"
    window[`${action}EventListener`]("click", this.closeOnClickOutside)
    window[`${action}EventListener`]("focusin", this.closeOnFocusOutside)
    window[`${action}EventListener`]("keydown", this.closeOnEscapeKey)
  }

  closeOnClickOutside = (event) => {
    if (!this.contains(event.target)) this.open = false
  }

  closeOnFocusOutside = (event) => {
    if (!this.contains(event.target)) this.open = false
  }

  closeOnEscapeKey = (event) => {
    if (event.key == "Escape") {
      this.open = false
      if (this.contains(event.target)) {
        this.summaryElement.focus()
      }
    }
  }

  // Option creation ("allow-create" attribute)

  updatePendingOption() {
    if (!this.allowCreate) return
    this.pendingOption?.remove()

    const value = this.validateOptionValue(this.query)
    if (!value || this.findOption(value)) return

    const option = (this.pendingOption ??= this.createOption())
    option.addEventListener("change", this.commit, { once: true })
    this.addOption(this.updateOption(option, value, `Add ${value}`))
  }

  activatePendingOption() {
    if (!this.pendingOption) return
    const value = this.valueForOption(this.pendingOption)
    const option = this.createOption(value)
    this.pendingOption.replaceWith(option)
    this.pendingOption = null
    return option
  }

  findOrCreateOption(value, label) {
    return this.findOption(value, label) || this.createOption(value, label)
  }

  findOption(value, label = value) {
    for (const option of this.options) {
      const optionValue = this.valueForOption(option)
      const optionLabel = this.labelForOption(option)
      if (value == optionValue || label == optionLabel) return option
      if (value.includes(optionValue) && label.includes(optionLabel)) return option
    }
  }

  createOption(value, label = value) {
    const option = this.optionTemplate.cloneNode(true)
    return this.updateOption(option, value, label)
  }

  updateOption(option, value, label) {
    if (value) option.querySelector("input").value = value
    if (label) option.querySelector("output").value = label
    if (label) option.setAttribute("data-label", label)
    return option
  }

  addOption(option) {
    const group = this.groups[0]
    if (group) group.prepend(option)
  }

  extractOptionValues(text) {
    const values = []
    for (const part of text.split(/,|\n/)) {
      const value = this.validateOptionValue(part)
      if (value) values.push(value)
    }
    return values
  }

  validateOptionValue(value = "") {
    const input = (this.validationInputElement ??= this.inputElement.cloneNode())
    if ((input.value = value.trim()) && input.checkValidity()) return input.value
  }

  // Helpers

  allHidden = (elements) => elements.every(this.isHidden)

  isHidden = (element) => element.hidden

  findGroups = (root) => [...root.querySelectorAll("[role=group]")]

  findOptions = (root) => [...root.querySelectorAll("[role=option]")]

  compareOptions = (a, b) => this.sortStringForOption(a).localeCompare(this.sortStringForOption(b))

  sortStringForOption = (option) => `${this.optionIsSelected(option) ? 0 : 1}${this.labelForOption(option)}`

  selectOption = (option) => Object.assign(option.querySelector("input"), { checked: true })

  optionIsSelected = (option) => Boolean(option.querySelector(":checked"))

  valueForOption = (option) => option.querySelector("input").value

  labelForOption = (option) => option.getAttribute("data-label") || option.textContent

  // Properties

  get allowCreate() {
    return this.hasAttribute("allow-create")
  }

  get open() {
    return this.detailsElement.open
  }

  set open(value) {
    this.detailsElement.open = value
  }

  get query() {
    return this.inputElement.value
  }

  set query(value) {
    this.inputElement.value = value
  }

  set summary(value) {
    this.summaryElement.textContent = value
  }

  get selectedOptionLabels() {
    return this.selectedOptions.map(this.labelForOption)
  }

  get selectedOptionSummary() {
    const count = this.selectedOptionCount
    return `${count} ${count == 1 ? this.optionDescriptionSingular : this.optionDescriptionPlural}`
  }

  get selectedOptionCount() {
    return this.containerElement.querySelectorAll(":checked").length
  }

  get selectedOptions() {
    return this.options.filter(this.optionIsSelected)
  }

  get selectableOptions() {
    return this.options.filter((option) => !this.optionIsSelected(option) && !this.isHidden(option))
  }

  get optionTemplate() {
    return this.querySelector("template[data-option]").content.firstElementChild
  }

  get options() {
    return this.findOptions(this.containerElement)
  }

  get groups() {
    return this.findGroups(this.containerElement)
  }

  get optionDescriptionSingular() {
    return this.getAttribute("option-description-singular")
  }

  get optionDescriptionPlural() {
    return this.getAttribute("option-description-plural")
  }
}

customElements.define("multi-select", MultiSelectElement)
