import { Controller } from "stimulus"
import { debounce, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["update"]

  static values = { url: String }

  initialize() {
    this.update = debounce(this.update.bind(this), 60)
    this.updatePreview = debounce(this.update, 3000)
  }

  async update() {
    const html = await fetch(this.url).then((resp) => resp.text())
    const root = new DOMParser().parseFromString(html, "text/html")

    const selector = `[data-${this.identifier}-target~="update"]`
    const elements = [...root.querySelectorAll(selector)]

    await nextFrame()

    for (const target of this.updateTargets) {
      const element = elements.shift() || target
      if (target.isEqualNode(element)) continue
      if (target.contains(document.activeElement)) continue
      target.replaceWith(element)
    }
  }

  get url() {
    const url = new URL(this.urlValue, location)
    for (const [key, value] of this.formData) {
      if (!key.match(/token|title|description/)) {
        url.searchParams.append(key, value)
      }
    }
    return url
  }

  get formData() {
    return new FormData(this.element)
  }
}
