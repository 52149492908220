import { camelize } from "./string_helpers"

export function memoize(object, key, value) {
  return Object.defineProperty(object, key, { value })[key]
}

export function arraysAreEqual(a = [], b = []) {
  if (a.length != b.length) return false
  return a.every((value, index) => value == b[index])
}

export function uniq(values) {
  return [...new Set(values)]
}

export function sole(values) {
  if (values.length == 1) return values[0]
}

export function camelizeKeys(object) {
  return Object.fromEntries(Object.entries(object).map(camelizeEntry))
}

function camelizeEntry([key, value]) {
  return [camelize(key), value]
}
