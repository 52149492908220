import "@github/include-fragment-element"

addEventListener("loadstart", setAcceptHeader, { capture: true })
addEventListener("include-fragment-replace", preserveChildrenWhenReplacementsAreEqual, { capture: true })

function setAcceptHeader({ target }) {
  if (target.localName == "include-fragment") {
    target.accept = "text/html; fragment"
  }
}

function preserveChildrenWhenReplacementsAreEqual(event) {
  const { detail, target } = event
  if (detail.fragment.childElementCount != 1) return

  const replacement = detail.fragment.firstElementChild
  if (replacement.tagName != target.tagName) return

  const [children, newChildren] = [target, replacement].map((e) => [...e.childNodes])
  if (children.length != newChildren.length) return
  if (children.some((e, i) => !e.isEqualNode(newChildren[i]))) return

  event.preventDefault()
  replacement.replaceChildren(...children)
  target.replaceWith(replacement)
}
