import { createElement, nextFrame } from "../../helpers"

class ClampContent extends HTMLElement {
  async connectedCallback() {
    await nextFrame()
    if (this.isOverflowing) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    if (!this.overlay) {
      this.overlay = createElement("div", { className: "overlay --show" })
      this.overlay.append(
        createElement("button", {
          className: "sh-btn sh-btn-text-muted sh-btn-sm sh-btn-outline",
          textContent: this.getAttribute("label") || "Expand",
          onclick: () => this.disable(),
        })
      )
    }
    this.append(this.overlay)
    this.classList.remove("--disabled")
  }

  disable() {
    this.overlay?.remove()
    this.classList.add("--disabled")
  }

  get isOverflowing() {
    const element = this.firstElementChild
    const { height } = element.getBoundingClientRect()
    return element.scrollHeight > Math.ceil(height)
  }
}

window.customElements.define("clamp-content", ClampContent)
