import { csrfToken, domReady } from "../helpers"

const authorizeTrelloError = function () {
  window.location = "/trello_connection/error"
}
const authorizeTrelloSuccess = function () {
  window.Trello.get("/members/me/boards", saveTrelloConnection, authorizeTrelloError)
}

const saveTrelloConnection = function (trelloBoardData) {
  const boards = {}

  trelloBoardData.forEach((b) => {
    if (!b.closed && !(b.prefs.permissionLevel === "private")) {
      boards[b.id] = { url: b.url, name: b.name }
    }
  })

  fetch("/trello_connection", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      authenticity_token: csrfToken(),
      token: window.Trello.token(),
      boards: boards,
    }),
    redirect: "follow",
  })
    .then((response) => {
      window.location = response.url
    })
    .catch(function () {
      window.location = "/trello_connection/error"
    })
}

const authorizeTrello = () =>
  window.Trello.authorize({
    type: "popup",
    name: document.querySelector('meta[name="application-name"]').content,
    expiration: "never",
    interactive: true,
    error() {
      authorizeTrelloError()
    },
    success() {
      authorizeTrelloSuccess()
    },
  })

domReady(function () {
  const el = document.querySelector("a#add-trello")
  if (!el) return

  el.addEventListener("click", function (e) {
    authorizeTrello()
    e.preventDefault()
  })
})
