import { Application } from "stimulus"

const controllers = import.meta.globEager("../**/*_controller.js")
const definitions = definitionsFromGlob(controllers)
const application = Application.start()
application.load(definitions)

// Adapted lovingly from https://github.com/ElMassimo/stimulus-vite-helpers

function definitionsFromGlob(controllerModules) {
  return Object.entries(controllerModules).map(definitionFromEntry).filter(Boolean)
}

function definitionFromEntry([key, controllerModule]) {
  const controllerConstructor = controllerModule.default
  if (typeof controllerConstructor != "function") return
  const identifier = identifierForGlobKey(key)
  if (identifier) return { identifier, controllerConstructor }
}

function identifierForGlobKey(key) {
  const pattern = /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+)(?:[/_-]controller\..+?)$/
  const name = (key.match(pattern) || [])[1]
  if (name) return name.replace(/_/g, "-").replace(/\//g, "--")
}
