import { Controller } from "stimulus"
import { nextFrame } from "../helpers"
const MIN_DESKTOP_VIEWPORT_WIDTH = 1024

export default class extends Controller {
  static targets = ["toggle", "sidepane"]

  async initialize() {
    await nextFrame()
    this.initialWidth = window.innerWidth
    if (this.hasToggleTarget && !this.hasSidepaneTarget) {
      this.toggleTarget.style.opacity = 0.25
      this.toggleTarget.style.pointerEvents = "none"
    }
  }

  update() {
    if (this.hasToggleTarget && this.hasSidepaneTarget) {
      if (this.sidepaneTarget.open === false && this.toggleTarget.classList.contains("active")) {
        this.toggleTarget.classList.remove("active")
      } else if (
        this.sidepaneTarget.open === false &&
        !this.toggleTarget.classList.contains("active") &&
        this.initialWidth >= MIN_DESKTOP_VIEWPORT_WIDTH
      ) {
        this.toggleTarget.classList.add("sh-bump")
      }
    }
  }

  toggle() {
    if (this.hasToggleTarget && this.hasSidepaneTarget) {
      this.sidepaneTarget.classList.toggle("hidden")
      this.toggleTarget.classList.toggle("active")
    }
  }
}
