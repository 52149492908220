import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkInsToggle", "hideWhenCheckInsDisabled", "summaryToggle", "hideWhenSummaryDisabled"]

  initialize() {
    this.update()
  }

  update() {
    const { checkInsDisabled, summaryDisabled } = this
    for (const target of this.hideWhenCheckInsDisabledTargets) target.hidden = checkInsDisabled
    for (const target of this.hideWhenSummaryDisabledTargets) target.hidden = summaryDisabled
  }

  get checkInsDisabled() {
    return this.hasCheckInsToggleTarget && !this.checkInsToggleTarget.checked
  }

  get summaryDisabled() {
    return this.hasSummaryToggleTarget && !this.summaryToggleTarget.checked
  }
}
