import { Controller } from "stimulus"
import { clickSubmitter, submitForm } from "../helpers"

export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this)
  }

  handleEvent(event) {
    if (event.key == "Enter" && !event.shiftKey) {
      event.preventDefault()
      const { form } = this.element
      clickSubmitter(form) || submitForm(form)
    }
  }
}
