import { Controller } from "stimulus"
import { delay } from "../helpers"

export default class extends Controller {
  static values = {
    properties: Object,
    seconds: Number,
  }

  async initialize() {
    await delay(this.secondsValue * 1000)

    for (const [key, value] of this.entries) {
      this.element.setAttribute(key, value)
    }
  }

  get entries() {
    return Object.entries(this.propertiesValue)
  }
}
