import { Controller } from "stimulus"
import { csrfToken } from "../helpers"

export default class extends Controller {
  static targets = ["input", "weak", "strong"]

  static values = {
    minLength: Number,
    minScore: Number,
    scoresUrl: String,
  }

  initialize() {
    this.update()
  }

  // Actions

  async update() {
    const score = await this.getScore()
    this.element.setAttribute("strength", score)
    this.weakTarget.hidden = score < 1 || score >= this.minScoreValue
    this.strongTarget.hidden = score < this.minScoreValue
  }

  // Private

  async getScore() {
    if (this.password.length < this.minLengthValue) return 0

    const resp = await fetch(this.scoresUrlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
      body: JSON.stringify({ password: this.password }),
    })

    return (await resp.json()).score
  }

  get password() {
    return this.inputTarget.value
  }
}
