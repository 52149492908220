import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["help", "error", "preview"]

  change(e) {
    if (this.hasPreviewTarget) {
      this.read(e)
    }
  }

  read(e) {
    if (window.FileReader) {
      let file = e.target.files[0]
      let reader = new FileReader()
      let preview = this.previewTarget
      const helpText = this.hasHelpTarget ? this.helpTarget : false
      const errorText = this.hasErrorTarget ? this.errorTarget : false

      if (file && file.type.match("image.*")) {
        reader.readAsDataURL(file)
      } else {
        if (helpText) {
          helpText.hidden = true
        }
        if (errorText) {
          errorText.hidden = false
        }
      }
      reader.onloadend = function () {
        preview.style.backgroundImage = `url('${reader.result}')`
        preview.querySelector("img").hidden = true
        if (helpText) {
          helpText.hidden = false
        }
        if (errorText) {
          errorText.hidden = true
        }
      }
    }
  }
}
