const appBadgeSupported = "setAppBadge" in navigator && "permissions" in navigator

export const maybeSetAppBadge = appBadgeSupported ? setAppBadge : noop

let currentCount
async function setAppBadge(count = 0) {
  // Possible states: "granted", "denied", "prompt"
  const { state } = await navigator.permissions.query({ name: "notifications" })
  // Permission was previously denied, we're done
  if (state == "denied") return
  // Avoid prompting for permission until we have a count to display
  if (state == "prompt" && !count && !currentCount) return
  // Set badge count, implicicitly prompting for permission unless previously granted
  try {
    await navigator.setAppBadge(count)
    currentCount = count
  } catch (error) {
    console.error("Failed to set app badge:", error)
  }
}

async function noop() {}
