import "@github/details-menu-element"

addEventListener("toggle", setActiveDetails, { capture: true })
addEventListener("click", closeActiveDetails, { capture: true })

let activeDetails

function setActiveDetails({ target }) {
  const menu = target.querySelector("details-menu")
  if (menu && menu.closest("details") == target) {
    if (target.open) {
      activeDetails = target
      repositionIfNeeded(menu)
    } else {
      activeDetails = null
    }
  }
}

function closeActiveDetails({ target }) {
  if (activeDetails && !activeDetails.contains(target)) {
    activeDetails.open = false
  }
}

function repositionIfNeeded(menu) {
  requestAnimationFrame(() => {
    const rect = menu.getBoundingClientRect()
    if (rect.left < 0) {
      menu.classList.remove("--right")
    } else if (rect.right > window.innerWidth) {
      menu.classList.add("--right")
    }

    if (rect.top < 0) {
      menu.classList.remove("--bottom")
    } else if (rect.bottom > window.innerHeight) {
      menu.classList.add("--bottom")
    }
  })
}
